<template xmlns="http://www.w3.org/1999/html">
    <UserProfileHeader
        :userInfo="userInfo"
        :profileInfo="authUserProfileInfo"
        :nav-links="navLinks"
        :counter="counter"
        :isStatsLoading="isStatsLoading"
    >
        <template #right-menu>
            <router-link to="/business/feedback/feedback-session/create" class="btn main-btn me-3"
                         v-if="userInfo.can(BusinessUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION)">
                {{ $t("Create Feedback") }}
            </router-link>
            <div class="me-0">
                <el-dropdown trigger="click" placement="bottom-end">
                    <span class="el-dropdown-link">
                        <button class="btn btn-sm btn-icon btn-bg-transparent btn-active-color-primary">
                            <i class="bi bi-three-dots fs-1 fw-bolder"></i>
                        </button>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>
                                <a :href="platformConfigs.platformLinks.Contactus" class="note-color w-100"
                                   target="_blank">{{ $t("Contact Support") }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <a :href="platformConfigs.platformLinks.Guides" class="note-color w-100"
                                   target="_blank">{{ $t("Guides") }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <a :href="platformConfigs.platformLinks.Terms" class="note-color w-100" target="_blank">{{ $t("Terms & Conditions") }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <a :href="platformConfigs.platformLinks.Privacy" class="note-color w-100"
                                   target="_blank">{{ $t("Privacy Policy") }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <a :href="platformConfigs.platformLinks.GDPRExport" class="note-color w-100"
                                   target="_blank">{{ $t("GDPR Export") }}</a>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </template>
    </UserProfileHeader>

    <router-view></router-view>
</template>
<script>
import store from "@/store";
import UserProfileHeader from "@/buying-teams/shared-components/settings/UserProfileHeader";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "UserSettings",
    components: { UserProfileHeader },
    data() {
        return {
            BusinessUserPermissionTypeEnum,
            isStatsLoading: true,
            navLinks: [
                {
                    label: "Overview",
                    link: "/business/settings/user-settings/overview"
                },
                {
                    label: "Settings",
                    link: "/business/settings/user-settings/settings"
                },
                // {
                //   label: 'Notifications',
                //   link: '/business/settings/user-settings/notifications'
                // },
                {
                  label: 'Sharing Policy',
                  link: '/business/settings/user-settings/sharing-policy'
                }
            ]
        };
    },
    computed: {
        platformConfigs() {
            return store.getters.platformConfigs;
        },
        userInfo() {
            return store.getters.currentUser;
        },
        authUserProfileInfo() {
            return store.getters.authUserProfileInfo;
        },
        counter() {
            let cnt = [
                {
                    label: "Total Score",
                    key: "score",
                    value: null,
                    statusClass: "average_score"
                },
                {
                    label: "Questions Replied",
                    key: "replies",
                    value: null,
                    statusClass: ""
                },
                {
                    label: "Requests & Ideas",
                    key: "intents_count",
                    value: null,
                    statusClass: ""
                }
            ];

            cnt.map(it => {
                it.value = this.authUserProfileInfo[it.key] === null ? "0" : this.authUserProfileInfo[it.key];
            });

            return cnt;
        }
    },
    async mounted() {
        setCurrentPageBreadcrumbs({ title: `${this.$t("hi")} ${this.userInfo.first_name},  <span>${this.$t("welcomeBack")}</span>` });
        store.dispatch("getAuthUserProfileInfo").finally(() => {
            this.isStatsLoading = false;
        });
        store.dispatch("fetchUserSettings");
    }
};
</script>
<style lang="scss" scoped>

</style>
